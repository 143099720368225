import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ItemWidget = (props) => {
    const { attributes } = props; console.log(attributes);
    // const wdgMediaClassName = (attributes?.themes) ? attributes?.themes?.media : "mx-auto min-width-100px min-height-200px";
    const wdgTitleClassName = (attributes?.themes) ? attributes?.themes?.title : "text-25px text-bold mb-3";
    const wdgDescriptionClassName = (attributes?.themes) ? attributes?.themes?.description : "text-16px line-height-20px mb-4";

    useEffect(() => {
        // 
    }, [attributes])

    return (
        <div className={"d-flex cursor-pointer"}>
            <div className={attributes?.themes?.icon}>{attributes?.logo}</div>
            <div className={""}>
                <h1 className={wdgTitleClassName + ""}>{attributes?.title}</h1>
                <div className={wdgDescriptionClassName + " opacity-8"}>{attributes?.description}</div>
                <div className={attributes?.buttons?.className + " d-flex " + (attributes?.buttons?.items ? "" : "")}>
                    {
                        attributes?.buttons?.items?.map((button, i) => {
                            return (
                                <div key={i} className={button.className + " d-flex text-14px p-2 ps-3 pe-3 rounded-3px"}>
                                    <span className={"text-bold"}>{button?.text}</span>
                                    <i className={""}></i>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default ItemWidget