import React, { useEffect } from 'react';
import AppRouterModule from "./app.router.module"

function App() {

  useEffect(() => {

  }, [])

  return (
    <div className="App">
      <AppRouterModule></AppRouterModule>
    </div>
  );
}

export default App;
