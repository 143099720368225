import React, { useEffect, useState } from "react";
import CaptionWidget from "../caption/caption.widget";

const CaptionCardGridWidget = (props) => {
    const [headline, setHeadline] = useState({});
    const [columns, setColumns] = useState([]);
    const { attributes } = props;

    useEffect(() => {
        if (attributes?.items?.length > 0) {
            if (attributes?.items.length > 0) {
                setHeadline(attributes?.items[0]);
                if (attributes?.items.length > 1) {
                    const columnItems = attributes?.items.slice(1);
                    setColumns(columnItems);
                }
            }
        }
    }, [attributes])

    return (
        <div {...props} className={""}>
            <div className={"position-absolute top-0px start-0px end-0px bottom-0px text-20px"}>
                <div className={"position-absolute top-0px start-0px end-0px bottom-0px mx-auto"}>
                    {/* banner caption area */}
                    <div className={"mx-auto mt-4 width-100 height-400px pt-4 ps-0 pe-0"}>
                        <div className={"d-flex justify-content-between align-items-start w-100"}>
                            <div className={"mb-0 me-3 w-50"}>
                                <div className={"w-80"}><CaptionWidget attributes={headline} /></div>
                            </div>
                            <div className={"mt-0 ms-3 p-0 w-50 d-flex"}>
                                {
                                    columns.map((columnAttributes, i) => {
                                        const itemClassName = (i === 0) ? " w-50 ps-0 pe-3" : ((i === attributes?.items.length - 1) ? " w-50 ps-3 pe-0" : " w-50 ps-3 pe-3")
                                        return <div key={i} className={itemClassName}>
                                            <CaptionWidget attributes={columnAttributes} />
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default CaptionCardGridWidget;