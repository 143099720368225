import React, { useEffect, useState } from "react";
import { BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
import LogoWidget from "../logo/logo.widget";
import PhoneCardWidget from "../tel/phone.card.widget";
import MailCardWidget from "../mailto/mail.card.widget";

const PageFooterWidget = (props) => {

    const { className, text, type } = props;

    const [logoAttributes, setLogoAttributes] = useState({});
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        setLogoAttributes({
            src: "logo-default",
            href: "",
            className: "height-60px width-200px"
        });
        setMenuList([
            {
                header: "Company",
                href: "",
                items: [
                    {
                        text: "Our Story",
                        href: ""
                    },
                    {
                        text: "Career",
                        href: ""
                    },
                    {
                        text: "Newsroom",
                        href: ""
                    },
                    {
                        text: "Corporate responsibility",
                        href: ""
                    },
                    {
                        text: "Trust Center",
                        href: ""
                    },
                    {
                        text: "Supply Chain",
                        href: ""
                    },
                    {
                        text: "Events",
                        href: ""
                    }
                ]
            },
            [
                {
                    header: "Campilax For",
                    href: "",
                    items: [
                        {
                            text: "Small & Medium Enterprises",
                            href: ""
                        },
                        {
                            text: "Prototype a Business",
                            href: ""
                        },
                        {
                            text: "Developers",
                            href: ""
                        }
                    ]
                },
                {
                    header: "For Mobile",
                    href: "",
                    items: [
                        {
                            text: "Apps for Android",
                            href: ""
                        },
                        {
                            text: "Apps for iOS",
                            href: ""
                        }
                    ]
                }
            ],
            {
                header: "Support",
                href: "",
                items: [
                    {
                        text: "Schedule a Demo Session",
                        href: ""
                    },
                    {
                        text: "Join Our Community",
                        href: ""
                    },
                    {
                        text: "FAQs",
                        href: ""
                    },
                    {
                        text: "Blog",
                        href: ""
                    },
                    {
                        text: "Report Bug",
                        href: ""
                    }
                ]
            },
            [
                {
                    header: "Contact",
                    href: "",
                    items: [
                        {
                            component: PhoneCardWidget,
                            items: ["(+234) - 81 7368 7900", "(+234) - 81 7368 7901"]
                        },
                        {
                            component: MailCardWidget,
                            items: ["customercare@campilax.com", "support@campilax.com"]
                        }
                    ]
                },
                {
                    items: [
                        {
                            href: "",
                            icon: BsFacebook
                        },
                        {
                            href: "",
                            icon: BsTwitter
                        },
                        {
                            href: "",
                            icon: BsInstagram
                        },
                        {
                            href: "",
                            icon: BsLinkedin
                        }
                    ]
                }
            ]
        ]);
    }, [props, className, type, text])

    const getMenuWidget = (menu, index) => {
        return (
            <div key={index} className={"w-100"}>
                <div className={"w-100 mb-3 text-bolder text-uppercase manrope-bold"}>{menu?.header}</div>
                <div className={"w-100 mb-3 text-13px"}>
                    {
                        menu?.items?.map((item, i) => {
                            if (!item?.component) {
                                return (
                                    <div key={"itm-" + i} className={"w-100 mb-2 text-capitalize"}>
                                        <Link to={"/company/our-story"} className={"text-dark text-decoration-none"}>{item.text}</Link>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={"itm-" + i} className={"w-100 mb-2 text-capitalize"}>
                                        {item.component({
                                            data: item.items
                                        })}
                                    </div>
                                )
                            }

                        })
                    }
                </div>
            </div>
        );
    }

    return (
        <div {...props} className={"position-relative w-100 bg-campilax-blue-opac1-theme " + (props.textSize ? "text-" + props.textSize + "px " : "")}>

            {/* separator section */}
            <div className={"position-relative mx-auto pt-5 pb-0"}>
                <div className={"position-absolute top--100px end-0px bottom-0px start-0px overflow-hidden z-index-1"}>
                    <div className={"position-relative height-100px skew--3deg bg-campilax-blue-opac1-theme"}>
                        <div className={"position-absolute top-0px start-0px- bottom-0px end-0px d-flex width-400px height-30px"}>
                            <div className={"position-absolute top--20px start-0px bottom-0px bg-campilax-green-theme width-60px height-50px z-index-0"}></div>
                            <div className={"position-absolute top-0px start-0px bottom-0px bg-campilax-blue-theme width-200px height-30px z-index-1"}></div>
                            <div className={"position-absolute top-0px end--20px bottom-0px bg-campilax-blue-theme width-200px height-30px z-index-1"}></div>
                            <div className={"position-absolute top-0px end-0px bottom--20px bg-campilax-yellow-theme width-60px height-50px"}></div>
                        </div>
                        {/*  */}
                        <div className={"position-absolute top--30px start-0px bottom-0px d-flex width-400px height-30px"}>
                            <div className={"position-absolute top--20px start-0px bottom-0px bg-campilax-green-theme width-60px height-50px z-index-0"}></div>
                            <div className={"position-absolute top-0px start-0px bottom-0px bg-campilax-blue-theme width-200px height-30px z-index-1"}></div>
                            <div className={"position-absolute top-0px end-0px bottom-0px bg-campilax-blue-theme width-180px height-30px z-index-1"}></div>
                            <div className={"position-absolute top-0px end-0px bottom--20px bg-campilax-yellow-theme width-60px height-50px"}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={""}>
                <div className={"min-height-250px d-flex justify-content-center align-items-center pt-5 pb-5"}>
                    <div className={"width-1300px d-flex justify-content-between text-13px"}>
                        <div className={"w-100 min-width-400px"}>
                            <div className={"w-100 mb-3 text-bolder text-uppercase manrope-bold"}>
                                <LogoWidget attributes={logoAttributes} />
                            </div>
                            <div className={"w-100 mb-3 text-13px"}>

                            </div>
                        </div>
                        {
                            menuList?.map((item, i) => {
                                if (!Array.isArray(item)) {
                                    return getMenuWidget(item, i);
                                } else {
                                    return (
                                        <div key={i} className={"w-100"}>
                                            {
                                                item.map((child, j) => {
                                                    return getMenuWidget(child, j);
                                                })
                                            }
                                        </div>
                                    )
                                }
                            })
                        }
                        {/* <div className={"w-100"}>
                            <div className={"w-100 mb-5"}>
                                <div className={"w-100 mb-3 text-bolder text-uppercase manrope-bold"}>Contact</div>
                                <div className={"w-100 mb-3 text-13px"}>
                                    <div className={"w-100 mb-2 text-capitalize"}>
                                        <PhoneCardWidget attributes={""} />
                                    </div>
                                    <div className={"w-100 mb-2 text-lower"}>
                                        <MailCardWidget attributes={""} />
                                    </div>
                                </div>
                            </div>
                            <div className={"w-100 mb-5 d-flex justify-content-start align-items-center"}>
                                <div className={"text-28px me-3"}><BsFacebook /></div>
                                <div className={"text-28px me-3"}><BsTwitter /></div>
                                <div className={"text-28px me-3"}><BsInstagram /></div>
                                <div className={"text-28px me-3"}><BsLinkedin /></div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={"height-40px d-flex justify-content-center align-items-center text-dark text-12px"}>
                    <div className={"width-1300px max-width-2140px d-flex justify-content-between"}>
                        <div className={"w-50"}>&copy; 2022 Campilax Solutions Limited. All rights reserved.</div>
                        <div className={"width-50 d-flex justify-content-end"}>
                            <div className={"ps-2 pe-2"}>Terms & Conditions</div>
                            <div className={"ps-2 pe-2"}>Privacy Policy</div>
                            <div className={"ps-2 pe-0"}>Accessibility</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PageFooterWidget;