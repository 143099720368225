import React, { useEffect } from "react";
import NavMenuWidget from "../nav.menu/nav.menu.widget";
import CaptionWidget from "../caption/caption.widget";
import LogoWidget from "../logo/logo.widget";

const HomePageHeaderWidget = (props) => {

    const { attributes } = props;
    const { header, banner } = attributes;

    useEffect(() => {
        //
    }, [attributes, header, banner])

    return (
        <div {...props} className={""}>
            <div className={"position-relative text-20px height-650px"}>
                {/* banner section */}
                <div className={"position-absolute top-0px start-0px end-0px bottom-0px d-flex justify-content-between"}>
                    <div className={"w-50 bg-campilax-blue-theme opacity-10"}></div>
                    <div className={"w-50 position-relative"}>
                        <div className={"position-absolute top-0px start-0px bottom-0px end-0px banner-5"}></div>
                        {/* <div className={"position-absolute top-0px start-0px bottom-0px end-0px z-index-1 bg-campilax-blue-theme-fade-bottom opacity-8"}></div> */}
                        <div className={"position-absolute top-0px start-0px bottom-0px end-0px z-index-1 bg-campilax-dark-theme-fade-bottom opacity-8"}></div>
                    </div>
                </div>
                <div className={"position-absolute top-0px start-0px end-0px bottom-0px max-width-1700px mx-auto"}>
                    {/* header nav area */}
                    <div className={"position-relative height-80px"}>
                        <div className={"position-absolute d-flex justify-content-between mx-auto mt-3 width-100 ps-5 pe-5 z-index-10"}>
                            <div className={"d-flex text-white align-items-center-"}>
                                <div className={"d-flex text-white me-1"}>
                                    <LogoWidget attributes={{
                                        src: "logo-default",
                                        href: "",
                                        className: "height-50px width-200px me-0"
                                    }} />
                                </div>
                                <div className={"d-flex text-white text-15px"}>
                                    <NavMenuWidget attributes={header.leftPane} />
                                </div>
                            </div>
                            <div className={"text-white text-15px"}>
                                <NavMenuWidget attributes={header.rightPane} />
                            </div>
                        </div>
                    </div>

                    {/* banner caption area */}
                    <div className={"d-flex justify-content-between align-items-center mx-auto mt-4 width-100 height-400px pt-5 ps-5 pe-5"}>
                        <div className={"w-50 max-width-600px ms-4 text-white"}>
                            <CaptionWidget attributes={banner} />
                        </div>
                        <div className={"w-50 text-white text-15px"}></div>
                    </div>
                </div>
                <div className={"position-absolute start-0px bottom--50px end-0px height-150px svg-banner-bottom z-index-2"}></div>
            </div>
        </div >
    );
}
export default HomePageHeaderWidget;