import React, { useEffect, useState } from "react";
import CaptionWidget from "../caption/caption.widget";

const BannerWidget = (props) => {
    const [headline, setHeadline] = useState({});
    const [columns, setColumns] = useState([]);
    const { attributes } = props;

    useEffect(() => {
        if (attributes?.items?.length > 0) {
            if (attributes?.items.length > 0) {
                setHeadline(attributes?.items[0]);
                if (attributes?.items.length > 1) {
                    const columnItems = attributes?.items.slice(1);
                    setColumns(columnItems);
                }
            }
        }
    }, [attributes])

    return (
        <div {...props} className={""}>
            <div className={"position-absolute top-0px start-0px end-0px bottom-0px text-20px"}>
                {/* banner section */}
                <div className={"position-absolute top-0px start-0px end-0px bottom-0px d-flex justify-content-between"}>
                    <div className={"w-50 position-relative"}>
                        <div className={"position-absolute top-0px start-0px bottom-0px end-0px banner-3"}></div>
                    </div>
                    <div className={"w-50 opacity-10"}></div>
                </div>
                <div className={"position-absolute top-0px start-0px end-0px bottom-0px max-width-1300px mx-auto"}>
                    {/* banner caption area */}
                    <div className={"d-flex justify-content-between align-items-start mx-auto mt-4 width-100 max-width-1300px height-400px pt-4 ps-0 pe-0"}>
                        <div className={"w-100 text-white text-15px min-height-100px"}></div>
                        <div className={"w-100 max-width-600px- ms-4 ps-5"}>
                            <div className={"mb-0"}><CaptionWidget attributes={headline} /></div>
                            <div className={"height-50px"}></div>
                            <div className={"mt-0 p-0 w-100 d-flex"}>
                                {
                                    columns.map((column, i) => {
                                        const itemClassName = (i === 0) ? " w-50 ps-0 me-4" : ((i === columns.length - 1) ? " w-50 ms-4 me-0" : " w-50 ms-4 me-4")
                                        return <div key={i} className={itemClassName}>
                                            <CaptionWidget attributes={column} />
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default BannerWidget;