import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const LogoWidget = (props) => {

    const logoElement = useRef();
    const { attributes } = props;

    useEffect(() => {
        //
    }, [attributes, logoElement])

    const handleClick = (e) => {
        const a = document.body.querySelector('a[id="' + attributes?.to + '"]');
        a.setAttribute('href', attributes?.to);
        // @ts-ignore
        a.click();
        e.cancelBubble = true;
        e.preventDefault();
    }

    return (
        <div className={"d-flex"}>
            <Link {...props} onClick={handleClick}>
                <div className={"d-flex text-white"}>
                    <i className={attributes?.src + " image-contain image-left " + attributes?.className}></i>
                </div>
            </Link>
            <a ref={logoElement} className={"position-absolute top-0px left-0px text-decoration-none"} id={props?.to} href="http://">&nbsp;</a>
        </div>
    );
}
export default LogoWidget;