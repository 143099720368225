import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Error404Page from "./pages/errors/error404.page";
import HomePage from "./pages/home/home.page";
import AboutPage from "./pages/about/about.page";

const AppRouterModule = () => {
  return (
    <div>
      <Router>
        <Suspense fallback={<Error404Page />}>
          <Routes>
            <Route path="/*" element={<HomePage />}></Route>
            <Route path="/about" element={<AboutPage />}></Route>
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default AppRouterModule;
