import React, { useEffect, useRef } from "react";

const ButtonWidget = (props) => {

    const buttonElement = useRef();
    const { attributes } = props;

    useEffect(() => {
        //
    }, [attributes, buttonElement])

    const noAction = () => {
        // 
    }

    return (
        <div className={"d-flex"}>
            <button className={"btn " + (attributes?.className ? attributes?.className : "btn-campilax-grey-theme text-15px rounded-pill")} onClick={attributes?.action ? attributes.action : () => noAction()}>
                <div className={"d-flex ps-2 pe-2"}>
                    <i className={(attributes?.logo ? "" : "hide ") + "m-0 me-2"}>
                        {attributes?.logo}
                    </i>
                    {attributes.text}
                    <i className={(attributes?.icon ? "" : "hide ") + "m-0 ms-1"}>
                        {attributes?.icon}
                    </i>
                </div>
            </button>
        </div>
    );
}
export default ButtonWidget;