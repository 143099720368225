import React, { useEffect } from "react";
import ItemWidget from "../item/item.widget";

const ItemListWidget = (props) => {

    const { attributes } = props;
    const { themes, items } = attributes;

    useEffect(() => {
        //
    }, [themes, items])

    return (
        <div className={"mb-0 p-3"}>
            <div className={attributes?.themes?.className + ""}>
                {
                    attributes?.items?.map((item, i) => {
                        item["themes"] = themes;
                        const itemClassName = "ps-2 pe-2 " + (i % 2 === 0) ? "pt-0 pb-4" : "";
                        return (
                            <div key={i} className={itemClassName + " w-100 text-decoration-none"} to={item.href}>
                                <ItemWidget attributes={item} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default ItemListWidget