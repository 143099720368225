import React, { useEffect } from "react";
import CardWidget from "../card/card.widget";

const CardGridCarouselWidget = (props) => {

    const { attributes } = props;
    const { themes, items } = attributes;

    const carouselItems = [
        {
            cards: [],
            default: true
        },
        {
            cards: [],
            default: false
        }
    ];

    useEffect(() => {
        //
    }, [themes, items])

    return (
        <div className={" mb-4"}>
            <div className={themes.className + " d-flex"}>
                {
                    attributes?.items.map((item, i) => {
                        const itemClassName = (i === 0) ? " ps-0 pe-3" : ((i === attributes?.items.length - 1) ? " ps-3 pe-0" : " ps-3 pe-3")
                        return <div key={i} className={item.className + itemClassName + " w-100 text-decoration-none"} to={item.href}>
                            <CardWidget attributes={(() => {
                                return {
                                    themes: themes,
                                    data: item
                                }
                            })()} />
                        </div>
                    })
                }
            </div>
            <div className={"d-flex justify-content-center"}>
                <div className={"d-flex align-items-center"}>
                    {
                        carouselItems.map((item, i) => {
                            const itemClassName = (i === 0) ? " ms-0 me-1" : ((i === attributes?.items.length - 1) ? " ms-1 me-0" : " ms-1 me-1")
                            const defaultClassName = (item.default === true) ? " width-30px rounded-pill bg-campilax-blue-theme" : " rounded-circle"
                            return (
                                <div key={i} className={"width-10px height-10px  bg-campilax-grey-theme" + itemClassName + defaultClassName}></div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}
export default CardGridCarouselWidget