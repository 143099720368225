import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

const MailToWidget = (props) => {

    useEffect(() => {
        const a = document.body.querySelector('a[id="' + props.to + '"]');
        // @ts-ignore
        a.previousSibling.setAttribute('href', 'mailto:' + props.to);
    }, [props,])

    const handleClick = useCallback((e) => {
        const a = document.body.querySelector('a[id="' + props.to + '"]');
        a.setAttribute('href', 'mailto:' + props.to);
        // @ts-ignore
        a.click();
        e.cancelBubble = true;
        e.preventDefault();
    }, [props])

    return (
        <div>
            <Link {...props} onClick={handleClick}></Link>
            <a className={"position-absolute top-0px left-0px text-decoration-none"} id={props.to} href="http://">&nbsp;</a>
        </div>
    );
}
export default MailToWidget;