const ALIGNMENTENUM = {
    LEFT: "left",
    TOP: "top",
    RIGHT: "right",
    BOTTOM: "bottom",
}

const POSITIONENUM = {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical"
}

export { ALIGNMENTENUM, POSITIONENUM }