import React from "react";
import { BsTelephoneFill } from "react-icons/bs";
import TelWidget from "./tel.widget";

const PhoneCardWidget = (props) => {
    return (
        <div>
            <div className={"text-dark text-decoration-none d-flex justify-content-start align-items-start"}>
                <div className={"icon icon-16px me-1"}><BsTelephoneFill /></div>
                <div className={""}>
                    {
                        props?.data?.map((phone, i) => {
                            return (
                                <div key={i} className={"mb-1"}>
                                    <TelWidget to={phone} className={"text-dark text-decoration-none justify-content-start align-items-center"}>
                                        <div className={"line-height-16px"}>{phone}</div>
                                    </TelWidget>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}
export default PhoneCardWidget;