import React, { useEffect } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import ButtonWidget from "../button/button.widget";

const NavMenuWidget = (props) => {

    const { attributes } = props;

    useEffect(() => {

    }, [])

    return (
        <div className={"d-flex align-items-center"}>
            {/* menu */}
            {
                attributes?.menu?.map((item, i) => {
                    if (item.component) {
                        return (
                            <div key={i} className={"dropdown-top position-relative d-flex"}>
                                <div className={"dropdown-button position-relative d-flex"}>
                                    <Link className={attributes?.className + " ps-1 pe-1 text-white text-decoration-none"} to={item.href}>
                                        <div className={"d-flex align-items-center me-3"}>
                                            {item.text}
                                            <i className={"m-0 p-0"}><MdOutlineKeyboardArrowDown /></i>
                                        </div>
                                    </Link>
                                </div>
                                <div className={"dropdown-callout shadow rounded-5px top-30px min-width-300px min-height-100px bg-campilax-blue-opac1-theme"}>
                                    {item.component({
                                        attributes: item.data
                                    })}
                                </div>
                            </div>
                        )
                    }
                    else if (item.items) {
                        return (
                            <div key={i} className={"position-relative d-flex"}>
                                <div className={"position-relative d-flex"}>
                                    <Link className={attributes?.className + " ps-1 pe-1 text-white text-decoration-none"} to={item.href}>
                                        <div className={"d-flex align-items-center me-3"}>
                                            {item.text}
                                            <i className={"m-0 p-0"}><MdOutlineKeyboardArrowDown /></i>
                                        </div>
                                    </Link>
                                </div>
                                <div className={"position-absolute"}>

                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div key={i} className={"position-relative d-flex"}>
                                <Link className={attributes?.className + " ps-1 pe-1 text-white text-decoration-none"} to={item.href}>
                                    <div className={"d-flex align-items-center me-3"}>
                                        {item.text}
                                    </div>
                                </Link>
                            </div>
                        )
                    }
                })
            }

            {/* button */}
            {
                attributes?.button?.map((item, i) => {
                    return (
                        <div key={i} className={"position-relative d-flex"}>
                            <ButtonWidget attributes={item} />
                        </div>
                    )
                })
            }
        </div>
    )
}
export default NavMenuWidget