import React from "react";
import MailToWidget from "../mailto/mailto.widget";
import { BsEnvelopeFill } from "react-icons/bs";

const MailCardWidget = (props) => {

    return (
        <div>
            <div className={"text-dark text-decoration-none d-flex justify-content-start align-items-start"}>
                <div className={"icon icon-16px me-1"}><BsEnvelopeFill /></div>
                <div className={""}>
                    {
                        props?.data?.map((email, i) => {
                            return (
                                <div key={i} className={"mb-1"}>
                                    <MailToWidget to={email} className={"text-dark text-decoration-none d-flex justify-content-start align-items-center"}>
                                        <div className={"line-height-16px"}>{email}</div>
                                    </MailToWidget>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}
export default MailCardWidget;