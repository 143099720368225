import React, { useState, useEffect } from "react";

const AboutComponent = () => {

    const [title, setTitle] = useState("");

    useEffect(() => {
        setTitle("About Campilax Solutions Limited")
    }, [])

    return (
        <div>
            <div>{title}</div>
        </div>
    )
}

export default AboutComponent;