import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const CardWidget = (props) => {
    const { attributes } = props;
    const wdgMediaClassName = (attributes?.themes) ? attributes?.themes?.media : "mx-auto min-width-100px min-height-200px";
    const wdgTitleClassName = (attributes?.themes) ? attributes?.themes?.title : "text-25px text-bold mb-3";
    const wdgDescriptionClassName = (attributes?.themes) ? attributes?.themes?.description : "text-16px line-height-20px mb-4";

    useEffect(() => {
        // 
    }, [attributes])

    return (
        <div className={""}>
            <div className={"position-relative mb-3 " + ((attributes?.data?.media) ? "show" : "hide")}>
                <div className={wdgMediaClassName}>
                    <div className={"position-absolute top-0px bottom-0px start-0px end-0px"}></div>
                    <div className={attributes?.data?.media?.banner + " position-absolute top-0px bottom-0px start-0px end-0px z-index-1"}></div>
                </div>
            </div>
            <h1 className={wdgTitleClassName + ""}>{attributes?.data?.title}</h1>
            <div className={wdgDescriptionClassName + ""}>{attributes?.data?.description}</div>
            <div className={attributes?.data.buttons?.className + " d-flex " + (attributes?.data.buttons?.items ? "" : "")}>
                {
                    attributes?.data.buttons?.items?.map((button, i) => {
                        return (
                            <div key={i} className={button.className + " d-flex text-14px p-2 ps-3 pe-3 rounded-3px"}>
                                <span className={"text-bold"}>{button?.text}</span>
                                <i className={""}></i>
                            </div>
                        )
                    })
                }
            </div>
            <div className={" d-flex "}>
                {
                    attributes?.data.links?.map((link, i) => {
                        return (
                            <div key={i} className={"d-flex text-14px p-2 ps-0 pe-3 rounded-3px"}>
                                <Link to={""} className={link.className + " text-decoration-none text-campilax-blue-theme"}>
                                    <span className={"text-bold"}>{link?.text}</span>
                                    <i className={""}></i>
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default CardWidget